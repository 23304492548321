import { Inject, Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { ExtendedRequestParams } from '@imunify360-api/misc';
import { XHR } from 'app/utils/xhr';
import { AbstractService } from 'app/services/abstract-service';
import { License, LicenseAPI } from '@imunify360-api/license';
import { AuthService } from './auth';
import { interval, BehaviorSubject, Subscription } from 'rxjs';
import { testMode } from 'app/services/misc';
import { switchMap } from 'rxjs/operators';
import { AuthAPI } from '@imunify360-api/auth';


const day = 24 * 60 * 60 * 1000;
const getTestData = () => ({
    status: !window['i360LicenseFail'],
    license_type: 'imunify360',
    expiration: (Date.now() + 2 * day) / 1000,
    user_limit: 10,
    user_count: 11,
    redirect_url: 'https://cln.cloudlinux.com',

    message: 'test message from CLN',
    upgrade_url: 'https://ui-test-server:55555/test/test.html',
    upgrade_url_360: 'https://ui-test-server:55555/test/test.html',
    ip_license: false,
});

@Injectable()
export class LicenseService extends AbstractService implements CanActivate {
    license: License;
    licenseChange = new BehaviorSubject(new License({status: true}));
    register = this.serviceRequest(LicenseAPI.register);
    getToken = this.rxServiceRequest(AuthAPI.get);
    updateTokenSubscription = Subscription.EMPTY;
    private updateTokenInterval = 150000;

    constructor(
        @Inject(XHR) private request: XHR,
        private router: Router,
        private authService: AuthService,
    ) {
        super(request);
        this.licenseChange.subscribe((license) => {
            this.license = license;
        });
    }

    registerViaPanel() {
        return this.xhr.post(new ExtendedRequestParams('applyLicenseFromPanel'));
    }

    getLicense(data: {}): License {
        return new License(testMode(this.constructor.name) ? getTestData() : data);
    }

    checkLicense(data: {}) {
        this.licenseChange.next(this.getLicense(data));

        if (this.authService.isClient) {
            return true;
        }
        if (this.license.isFailed() && this.router.url !== '/360/admin/license') {
            this.router.navigate(['/', PACKAGE, 'admin', 'license']);
            return false;
        } else if (!this.license.isFailed() && this.router.url === '/360/admin/license') {
            this.router.navigate(['/']);
        } else if (!this.license.upgradeAvailable && this.router.url === '/AV/upgrade') {
            this.router.navigate(['/']);
        }
        return true;
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.license.status) {
            this.router.navigate(['/']);
            return false;
        }

        return true;
    }

    getDefaultMockList() {
        return [];
    }

    autoUpdateToken(): void {
        this.updateTokenSubscription = interval(this.updateTokenInterval).pipe(
            switchMap(() =>
                this.getToken({username: this.authService.username}),
            ),
        ).subscribe(response => {
            this.authService.setToken(response.data.items);
        });
    }
}
