import { Component } from '@angular/core';

@Component({
  selector: 'no-content',
  template: `
    <div id="i360-404-page">
      <h1 translate>noContent.title</h1>
    </div>
  `,
})
export class NoContentComponent {

}
