import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot, CanActivate, CanLoad, Router, RouterStateSnapshot,
} from '@angular/router';
import { Route } from '@angular/router/src/config';
import { AuthService } from 'app/services/auth';


@Injectable({
    providedIn: 'root',
})
export class RoleGuard implements CanActivate, CanLoad {
    constructor(
        public authService: AuthService,
        private router: Router,
    ) {}

    async canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ) {
        return this.check(route.routeConfig ? route.routeConfig.path : void 0);
    }

    canLoad(route: Route) {
        return this.check(route.path);
    }

    private async check(path?: string) {
        if (path !== this.authService.role) {
            await this.router.navigate(['/', PACKAGE, this.authService.role], {
                replaceUrl: true,
            });
            return false;
        }
        return true;
    }
}
