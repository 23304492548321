import * as tslib_1 from "tslib";
import { HttpResponse, } from '@angular/common/http';
import { throwError as _throw } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
var Raven = require('raven-js'); // tslint:disable-line:variable-name
var truncateLongValues = function (data) {
    if (data && typeof data === 'object') {
        Object.entries(data).forEach(function (_a) {
            var _b = tslib_1.__read(_a, 2), key = _b[0], value = _b[1];
            if (typeof value === 'string' && value.length > 100) {
                data[key] = value.slice(0, 100) + '*****';
            }
            if (Array.isArray(value) && value.length > 5) {
                value.length = 5;
                value.push('*****');
            }
            truncateLongValues(value);
        });
    }
    return data;
};
var ɵ0 = truncateLongValues;
var captureRequest = function (data) {
    Raven.captureBreadcrumb({
        message: 'XHR started',
        category: 'action',
        data: data,
    });
};
var ɵ1 = captureRequest;
var captureResponse = function (response) {
    var data = truncateLongValues(JSON.parse(JSON.stringify(response)));
    Raven.captureBreadcrumb({
        message: 'XHR finished',
        category: 'action',
        data: data,
    });
};
var ɵ2 = captureResponse;
var SentryInterceptor = /** @class */ (function () {
    function SentryInterceptor() {
    }
    SentryInterceptor.prototype.intercept = function (req, next) {
        captureRequest({
            requestBody: req.body,
            url: req.urlWithParams,
        });
        return next.handle(req).pipe(tap(function (res) {
            if (res instanceof HttpResponse) {
                captureResponse({
                    responseBody: res.body,
                    requestBody: req.body,
                    url: req.urlWithParams,
                });
            }
        }), catchError(function (e) {
            captureResponse(e);
            Raven.captureException(e);
            return _throw(e);
        }));
    };
    return SentryInterceptor;
}());
export { SentryInterceptor };
export { ɵ0, ɵ1, ɵ2 };
