import { ResponseDataExceptItems } from '../misc';
import { LicenseType } from '../license';

export const DEFAULT_DATA: ResponseDataExceptItems = {
    strategy: 'PRIMARY_IDS',
    version: 'testing',
    eula: null,
    license: {
        id: '123',
        license_type: LicenseType.imunify360,
        user_count: 5,
        message: '',
        expiration: null,
        user_limit: 2147483647,
        status: true,
        redirect_url: 'https://cln.cloudlinux.com/clweb/public/imconv.xhtml?id=qwertyuiop',
    },
};
export const noItemsResponse = {data: DEFAULT_DATA};
export const emptyListResponse = {data: {...DEFAULT_DATA, items: []}};
export const emptyResponse = {data: {...DEFAULT_DATA, items: null}};
