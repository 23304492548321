import { I360Role } from './app/services/auth';

declare const adminAssets: string;
declare const clientAssets: string;
declare const staticPath: string;

declare const i360role: any;

const aPath = typeof adminAssets === 'undefined' ? '' : (
    typeof i360role !== 'undefined' && i360role === I360Role.admin ? adminAssets : clientAssets);
export const assetsPath = `${aPath}${typeof staticPath === 'undefined' ? 'static/' : staticPath}`;
