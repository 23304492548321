var _a;
import { RequestParams } from './misc';
var LicenseAPI = /** @class */ (function () {
    function LicenseAPI() {
    }
    LicenseAPI.register = function (licenseKey) { return new RequestParams(['register'], { regkey: licenseKey }); };
    LicenseAPI.unregister = function () { return new RequestParams(['unregister']); };
    LicenseAPI.version = function () { return new RequestParams(['version']); };
    LicenseAPI.rstatus = function () { return new RequestParams(['rstatus']); };
    return LicenseAPI;
}());
export { LicenseAPI };
/**
 * Defines available features and upgrade options.
 */
export var Product;
(function (Product) {
    Product[Product["imunifyAV"] = 0] = "imunifyAV";
    Product[Product["imunifyAVPlus"] = 1] = "imunifyAVPlus";
    Product[Product["imunify360"] = 2] = "imunify360";
})(Product || (Product = {}));
/**
 * Defines available features and upgrade options.
 */
export var LicenseType;
(function (LicenseType) {
    LicenseType["imunifyAV"] = "imunifyAV";
    LicenseType["imunifyAVPlus"] = "imunifyAVPlus";
    LicenseType["imunify360Trial"] = "imunify360Trial";
    LicenseType["imunify360"] = "imunify360";
})(LicenseType || (LicenseType = {}));
/**
 * Used to distinguish which plugin and RPM/DEB package is currently installed.
 */
export var Package;
(function (Package) {
    Package["imunifyAV"] = "AV";
    Package["imunify360"] = "360";
})(Package || (Package = {}));
var productIdToReadableName = (_a = {},
    _a[Product.imunifyAV] = 'ImunifyAV',
    _a[Product.imunifyAVPlus] = 'ImunifyAV+',
    _a[Product.imunify360] = 'Imunify360',
    _a);
var License = /** @class */ (function () {
    function License(data) {
        /* Effective product (e.g. what logo to use). Depends on PACKAGE and license_type. */
        this.product = Product.imunifyAV;
        /**
         * Message from CLN
         */
        this.message = '';
        /**
         * In case of upgrade do not propose to enter license key
         */
        this.ip_license = false; // tslint:disable-line:variable-name
        Object.assign(this, data);
        this.redirect_url = data.redirect_url || '';
        this.product = this.getProduct();
        this.productName = productIdToReadableName[this.product];
    }
    Object.defineProperty(License.prototype, "cleanupAllowed", {
        get: function () {
            return this.product === Product.imunify360
                || (this.product === Product.imunifyAVPlus && this.status);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(License.prototype, "upgradeAvailable", {
        get: function () {
            return this.product < Product.imunify360 && this.license_type !== LicenseType.imunify360;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Applicable only to Package.imunify360 - we block the whole UI there
     * if the license is not sufficient.
     * While in ImunifyAV we are just forbidding cleanup actions.
     */
    License.prototype.isFailed = function () {
        return PACKAGE === Package.imunify360 && !this.status;
    };
    License.prototype.hasMessage = function () {
        return !!this.message;
    };
    License.prototype.isLimitExceeded = function () {
        return this.user_count != null && this.user_count > this.user_limit;
    };
    License.prototype.getProduct = function () {
        switch (PACKAGE) {
            case Package.imunify360:
                return Product.imunify360;
            case Package.imunifyAV:
                return !this.license_type || this.license_type === LicenseType.imunifyAV
                    ? Product.imunifyAV
                    : Product.imunifyAVPlus;
            default:
                throw new Error("Unknown package " + PACKAGE);
        }
    };
    return License;
}());
export { License };
