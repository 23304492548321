import { Injectable } from '@angular/core';
import { AgentStatus } from '@imunify360-api/misc';

@Injectable()
export class AppState {
    version = VERSION;
    csf: boolean;
    imunifyStatus: AgentStatus = AgentStatus.OK;
    imagePath: string = `${require('../../assets').assetsPath}images`;
    refresherEnabled = false;
    mousePositonY: number = 0;
}
