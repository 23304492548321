// es6 import not working with es2016 target (in unit tests)
import { of, BehaviorSubject, EMPTY, Observable } from 'rxjs';
import * as jwtDecode from 'jwt-decode';
import {
    CanActivate,
    CanLoad, Router,
} from '@angular/router';
import { Location } from '@angular/common';
import { Panel } from 'app/utils/panel';
import { XHR } from 'app/utils/xhr';
import { filter, switchMap } from 'rxjs/operators';
import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest, HttpResponse,
} from '@angular/common/http';
import { AgentResponse } from '@imunify360-api/misc';
import { Injectable, Injector } from '@angular/core';
import { postOnly } from 'app/interceptors/utils';
import { INVALID_TOKEN_MESSAGE } from '@imunify360-api/auth';
import { LicenseService } from 'app/services/license';

export const TOKEN_FIELD_NAME = 'jwt';
export const TOKEN_LOCAL_STORAGE_FIELD_NAME = 'I360_AUTH_TOKEN';
declare const i360role: any;

export enum I360Role {
    admin = 'admin',
    client = 'client',
    none = 'none',
}

type TokenData = {
    user_type: I360Role,
    username: string,
};

// ToDo: split this class into several cohesive single purposed classes
@Injectable()
export class AuthService implements HttpInterceptor, CanActivate, CanLoad {
    loginChange = new BehaviorSubject(false);
    role: I360Role;
    username: string;

    constructor(
        public xhr: XHR,
        private location: Location,
        private injector: Injector,
    ) {}

    get isAdmin(): boolean {
        return this.role === I360Role.admin;
    }
    get isClient(): boolean {
        return this.role === I360Role.client;
    }

    logout() {
        this.setToken('');
        this.goToLoginPage();
    }

    goToLoginPage() {
        // We should save queryParams in url
        const router = this.injector.get(Router);
        const url = this.location.path(false);
        const urlBeforeQuestionMark = url.split('?')[0];
        router.navigate(['/', 'login'], {
            queryParams: {
                targetUrl: urlBeforeQuestionMark === '/login'
                    ? router.parseUrl(url).queryParams.targetUrl : url,
            },
        });
    }

    addTokenToRequest(req: HttpRequest<any>) {
        if (req.body) {
            // immutability!
            const copyOfBody = JSON.parse(JSON.stringify(req.body));
            copyOfBody.params[TOKEN_FIELD_NAME] = this.getToken();
            req = req.clone({
                body: copyOfBody,
            });
        }
        return req;
    }

    @postOnly
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const panel = this.injector.get(Panel);
        if (panel.isNoPanel) {
            if (this.role !== I360Role.none) {
                req = this.addTokenToRequest(req);
                return next.handle(req).pipe(
                    switchMap(event => {
                        if (event instanceof HttpResponse) {
                            const response: AgentResponse = event.body;
                            if (response.messages === INVALID_TOKEN_MESSAGE) {
                                this.logout();
                                return this.handleLogout();
                            }
                        }
                        return of(event);
                    }),
                );
            }

            this.goToLoginPage();
            return this.handleLogout();
        }

        return next.handle(req);
    }

    handleLogout() {
        return EMPTY;
    }

    getToken() {
        return localStorage.getItem(TOKEN_LOCAL_STORAGE_FIELD_NAME) || '';
    }

    setToken(newToken: string) {
        localStorage.setItem(TOKEN_LOCAL_STORAGE_FIELD_NAME, newToken);
        this.parseToken();
    }

    parseToken() {
        if (typeof i360role !== 'undefined') {
            // for panels
            this.role = i360role;
        } else {
            // not for panel extension
            const router = this.injector.get(Router);
            const urlTree = router.parseUrl(this.location.path(false));
            // query params can not be accessed in service via activatedRoute
            const urlToken = urlTree.queryParams.token;
            if (urlToken) {
                localStorage.setItem(TOKEN_LOCAL_STORAGE_FIELD_NAME, urlToken);
                delete urlTree.queryParams.token;
                // can not use 'navigate' because router is not initialized
                // no queryParams - queryParamsHandling: merge will not work
                // no url at all - navigate([]) will not navigate to current url but will to root
                router.navigateByUrl(urlTree || '/', {
                    replaceUrl: true,
                });
            }
            let payload: TokenData;
            try {
                payload = jwtDecode<TokenData>(this.getToken());
            } catch (e) {
                payload = {user_type: I360Role.none, username: ''};
            }
            this.role = payload.user_type;
            this.username = payload.username;
        }
        this.loginChange.next(this.role !== I360Role.none);
    }

    canActivate(): Observable<boolean> | Promise<boolean> | boolean {
        return this.canLoad();
    }

    canLoad(): Observable<boolean> | boolean {
        if (this.role === I360Role.none) {
            return true;
        } else {
            this.injector.get(Router).navigate(['/', PACKAGE, this.role], {
                replaceUrl: true,
            });
            return false;
        }
    }

}
