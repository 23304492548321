import { Component } from '@angular/core';
import { AuthService, I360Role } from 'app/services/auth';

@Component({
    selector: 'app',
    template: '<router-outlet></router-outlet>',
})
export class RootComponent {
    constructor(public authService: AuthService,
    ) {
        authService.parseToken();
        if (authService.role === I360Role.none) {
            authService.goToLoginPage();
        }
    }
}
