import { AgentResponse, AgentStatus } from '@imunify360-api/misc';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { of, EMPTY, Observable } from 'rxjs';
import { catchError, share, switchMap } from 'rxjs/operators';
import { Package } from '@imunify360-api/license';
import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
} from '@angular/common/http';
import { AppState } from 'app/core/app.service';
import { postOnly } from './utils';
import {
    InstallationBackendService,
} from 'app/components/installation/installation-backend.service';
import { AuthService } from 'app/services/auth';

const systemErrorMessage = 'System error 2: No such file or directory';
const importErrorMessage = 'ImportError: No module named';
const logTabUrl =  `/installation/progress/log`;
const slidesTabUrl = `/installation/progress/slides`;

@Injectable()
export class ImunifyStatusInterceptor implements HttpInterceptor {
    private getInstallationStatus: Observable<any>;

    constructor(
        private router: Router,
        private injector: Injector,
        private appState: AppState,
    ) {}

    @postOnly
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((error) => {
                try {
                    if (error.error.includes(importErrorMessage)) {
                        const result = this.updateAndCheckStatus(AgentStatus.INSTALLING);
                        if (result) return result;
                    }
                    if (error.error.includes(systemErrorMessage)) {
                        if (this.injector.get(AuthService).isClient) {
                            this.updateAndCheckStatus(AgentStatus.INSTALLING);
                            return EMPTY;
                        }
                        if (!this.getInstallationStatus) {
                            this.getInstallationStatus = this.injector
                                .get(InstallationBackendService).status({
                                    offset: 0,
                                    limit : 1,
                                }).pipe(share());
                        }
                        this.getInstallationStatus.subscribe();
                        return EMPTY;
                    }
                } catch {}
                throw error;
            }),
            switchMap((event) => {
                if (event instanceof HttpResponse) {
                    const res: AgentResponse<any> = event.body;
                    const status = res.status || AgentStatus.OK;
                    const result = this.updateAndCheckStatus(status);

                    if (result) return result;
                }

                return of(event);
            }),
        );
    }


    reload() {
        location.reload();
    }
    /**
     * This method was extracted from previous one because one more place of usage was needed
     * `return EMPTY` means return from parent method. And `return;` looks like analog of `break;`
     */
    private updateAndCheckStatus(status: AgentStatus): Observable<never> | undefined {
        this.appState.imunifyStatus = status;

        switch (this.appState.imunifyStatus) {
            case AgentStatus.OK:
                if (this.router.url.startsWith('/installation')) {
                    this.router.navigate(['/'], { replaceUrl: true });
                    this.reload();
                }
                return;
            case AgentStatus.INSTALLING:
                if (!this.router.url.startsWith('/installation')) {
                    this.router.navigateByUrl(slidesTabUrl, { replaceUrl: true });
                    return EMPTY;
                }
                return;
            case AgentStatus.NOT_INSTALLED:
                if (this.router.url !== '/installation') {
                    if (this.injector.get(AuthService).isClient) {
                        this.router.navigateByUrl(slidesTabUrl, {replaceUrl: true});
                    } else {
                        this.router.navigateByUrl('/installation', {replaceUrl: true});
                    }
                    return EMPTY;
                }
                return;
            case AgentStatus.FAILED_TO_INSTALL:
                if (PACKAGE === Package.imunify360) {
                    if (this.router.url !== logTabUrl) {
                        if (this.injector.get(AuthService).isClient) {
                            this.router.navigateByUrl(slidesTabUrl, {replaceUrl: true});
                        } else {
                            this.router.navigateByUrl(logTabUrl, { replaceUrl: true });
                        }
                        return EMPTY;
                    }
                }
                return;
            case AgentStatus.STOPPED:
                this.router.navigate(['installation', 'stopped-service'], {
                    replaceUrl: true,
                });
                return EMPTY;
            default:
                return;
        }
    }
}
