import { Injectable } from '@angular/core';
import { OverlayContainer } from '@angular/cdk/overlay';
@Injectable()
export class I360OverlayContainer extends OverlayContainer {
    constructor() {
        super(document);
    }
    protected _createContainer() {
        super._createContainer();
        const app = document.querySelector('.i360-app app');
        if (app) {
            app.appendChild(this._containerElement);
        }
    }
}
