
export const postOnly = (target: Object, propertyKey: string, descriptor: PropertyDescriptor) => {
    let originalMethod = descriptor.value;
    descriptor.value = function (req, next) {
        if (req.method === 'POST') {
            return originalMethod.apply(this, [req, next]);
        } else {
            return next.handle(req);
        }
    };

    return descriptor;
};
