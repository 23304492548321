import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot, CanActivate,
    CanLoad, Route, Router, RouterStateSnapshot, UrlSegment,
} from '@angular/router';


@Injectable({
    providedIn: 'root',
})
export class PackageGuard implements CanActivate, CanLoad {
    constructor(public router: Router) {}

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.check(route.routeConfig ? route.routeConfig.path : void 0);
    }

    canLoad(route: Route, segments: UrlSegment[]) {
        return this.check(route.path);
    }

    private async check(path) {
        if (path !== PACKAGE) {
            await this.router.navigate(['/', PACKAGE], {replaceUrl: true});
            return false;
        }
        return true;
    }
}
