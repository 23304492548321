import * as tslib_1 from "tslib";
// es6 import not working with es2016 target (in unit tests)
import { of, BehaviorSubject, EMPTY, Observable } from 'rxjs';
import * as jwtDecode from 'jwt-decode';
import { Router, } from '@angular/router';
import { Location } from '@angular/common';
import { Panel } from 'app/utils/panel';
import { XHR } from 'app/utils/xhr';
import { switchMap } from 'rxjs/operators';
import { HttpHandler, HttpRequest, HttpResponse, } from '@angular/common/http';
import { Injector } from '@angular/core';
import { postOnly } from 'app/interceptors/utils';
import { INVALID_TOKEN_MESSAGE } from '@imunify360-api/auth';
export var TOKEN_FIELD_NAME = 'jwt';
export var TOKEN_LOCAL_STORAGE_FIELD_NAME = 'I360_AUTH_TOKEN';
export var I360Role;
(function (I360Role) {
    I360Role["admin"] = "admin";
    I360Role["client"] = "client";
    I360Role["none"] = "none";
})(I360Role || (I360Role = {}));
// ToDo: split this class into several cohesive single purposed classes
var AuthService = /** @class */ (function () {
    function AuthService(xhr, location, injector) {
        this.xhr = xhr;
        this.location = location;
        this.injector = injector;
        this.loginChange = new BehaviorSubject(false);
    }
    Object.defineProperty(AuthService.prototype, "isAdmin", {
        get: function () {
            return this.role === I360Role.admin;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthService.prototype, "isClient", {
        get: function () {
            return this.role === I360Role.client;
        },
        enumerable: true,
        configurable: true
    });
    AuthService.prototype.logout = function () {
        this.setToken('');
        this.goToLoginPage();
    };
    AuthService.prototype.goToLoginPage = function () {
        // We should save queryParams in url
        var router = this.injector.get(Router);
        var url = this.location.path(false);
        var urlBeforeQuestionMark = url.split('?')[0];
        router.navigate(['/', 'login'], {
            queryParams: {
                targetUrl: urlBeforeQuestionMark === '/login'
                    ? router.parseUrl(url).queryParams.targetUrl : url,
            },
        });
    };
    AuthService.prototype.addTokenToRequest = function (req) {
        if (req.body) {
            // immutability!
            var copyOfBody = JSON.parse(JSON.stringify(req.body));
            copyOfBody.params[TOKEN_FIELD_NAME] = this.getToken();
            req = req.clone({
                body: copyOfBody,
            });
        }
        return req;
    };
    AuthService.prototype.intercept = function (req, next) {
        var _this = this;
        var panel = this.injector.get(Panel);
        if (panel.isNoPanel) {
            if (this.role !== I360Role.none) {
                req = this.addTokenToRequest(req);
                return next.handle(req).pipe(switchMap(function (event) {
                    if (event instanceof HttpResponse) {
                        var response = event.body;
                        if (response.messages === INVALID_TOKEN_MESSAGE) {
                            _this.logout();
                            return _this.handleLogout();
                        }
                    }
                    return of(event);
                }));
            }
            this.goToLoginPage();
            return this.handleLogout();
        }
        return next.handle(req);
    };
    AuthService.prototype.handleLogout = function () {
        return EMPTY;
    };
    AuthService.prototype.getToken = function () {
        return localStorage.getItem(TOKEN_LOCAL_STORAGE_FIELD_NAME) || '';
    };
    AuthService.prototype.setToken = function (newToken) {
        localStorage.setItem(TOKEN_LOCAL_STORAGE_FIELD_NAME, newToken);
        this.parseToken();
    };
    AuthService.prototype.parseToken = function () {
        if (typeof i360role !== 'undefined') {
            // for panels
            this.role = i360role;
        }
        else {
            // not for panel extension
            var router = this.injector.get(Router);
            var urlTree = router.parseUrl(this.location.path(false));
            // query params can not be accessed in service via activatedRoute
            var urlToken = urlTree.queryParams.token;
            if (urlToken) {
                localStorage.setItem(TOKEN_LOCAL_STORAGE_FIELD_NAME, urlToken);
                delete urlTree.queryParams.token;
                // can not use 'navigate' because router is not initialized
                // no queryParams - queryParamsHandling: merge will not work
                // no url at all - navigate([]) will not navigate to current url but will to root
                router.navigateByUrl(urlTree || '/', {
                    replaceUrl: true,
                });
            }
            var payload = void 0;
            try {
                payload = jwtDecode(this.getToken());
            }
            catch (e) {
                payload = { user_type: I360Role.none, username: '' };
            }
            this.role = payload.user_type;
            this.username = payload.username;
        }
        this.loginChange.next(this.role !== I360Role.none);
    };
    AuthService.prototype.canActivate = function () {
        return this.canLoad();
    };
    AuthService.prototype.canLoad = function () {
        if (this.role === I360Role.none) {
            return true;
        }
        else {
            this.injector.get(Router).navigate(['/', PACKAGE, this.role], {
                replaceUrl: true,
            });
            return false;
        }
    };
    tslib_1.__decorate([
        postOnly,
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [HttpRequest, HttpHandler]),
        tslib_1.__metadata("design:returntype", Observable)
    ], AuthService.prototype, "intercept", null);
    return AuthService;
}());
export { AuthService };
