import '../styles/global/app.style.scss';
import 'hammerjs';
import { AppState } from 'app/core/app.service';
import { getWindow } from 'app/core/window';
import { DomSanitizer } from '@angular/platform-browser';
import { Panel } from 'app/utils/panel';
import { MatIconRegistry } from '@angular/material/icon';
import { applyCsrfStrategy, enableSvgIcons } from 'app/core/misc';
var ɵ0 = getWindow, ɵ1 = { float: 'never' };
var AppModule = /** @class */ (function () {
    function AppModule(iconRegistry, sanitizer, panel, appState) {
        if (panel.isPlesk) {
            applyCsrfStrategy();
        }
        enableSvgIcons(iconRegistry, appState, sanitizer);
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0, ɵ1 };
