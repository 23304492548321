import { Injectable, Optional } from '@angular/core';
import { from, Observable } from 'rxjs';
import { TranslateLoader } from '@ngx-translate/core';
import { AppState } from 'app/core/app.service';


@Injectable()
export class TranslateHttpLoader extends TranslateLoader {
    getTranslation(lang: string): Observable<object> {
        return from(import(`i18n/${lang}.i18n.txt`));
    }
}
