import { AbstractService } from 'app/services/abstract-service';
import { InstallationAPI } from '@imunify360-api/installation';
import { Injectable } from '@angular/core';
import { responses } from '@imunify360-api/stubs/installation';
import { Mock } from 'app/services/mock';

@Injectable({
    providedIn: 'root',
})
export class InstallationBackendService extends AbstractService {
    status = this.rxServiceRequest(InstallationAPI.status);
    start = this.serviceRequest(InstallationAPI.start);

    getDefaultMockList() {
        return !TEST ? [] : [
            Mock.add(InstallationAPI.status, responses.pieceOfLog),
            Mock.add(InstallationAPI.start, responses.serviceFailedToInstall),
        ];
    }
}
