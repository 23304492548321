import { RequestParams } from '@imunify360-api/misc';
import { DEFAULT_DATA } from '@imunify360-api/stubs/misc';


export interface DefaultMock<P, R, E extends R> {
    response: {data: E} | ((params: P) => {data: E});
    api(sourceParams): RequestParams<R>;
}

export class Mock {
    static defaultData = DEFAULT_DATA;
    static add<P, R, E extends R>(
        api: (sourceParams: P) => RequestParams<R>,
        response: {data: E},
    ): DefaultMock<P, R, E> {
        return {api, response};
    }
    static addFn<P, R, E extends R> (
        api: (sourceParams: P) => RequestParams<R>,
        getResponse: (sourceParams: P) => {data: E},
    ): DefaultMock<P, R, E> {
        return {api, response: getResponse};
    }
}
