import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from 'app/services/auth';
import { postOnly } from 'app/interceptors/utils';

declare const clientAction: string;
declare const adminAction: string;

@Injectable()
export class UrlInterceptor implements HttpInterceptor {
    constructor(
        private authService: AuthService,
    ) {}

    action = () => this.authService.isAdmin ? adminAction : clientAction;

    @postOnly
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // this code should be present in xhr.
        const newUrl = req.url.startsWith('http') ? req.url : this.action() + req.url;
        return next.handle(req.clone({url: newUrl}));
    }
}
