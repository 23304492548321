import { Injectable } from '@angular/core';
import {
    AgentResponse, AgentResponseData, ApiMethod, ListResponseData, RequestParams, WindowInterface,
} from '@imunify360-api/misc';
import { XHR } from 'app/utils/xhr';
import { timer ,  Observable } from 'rxjs';
import { map, mapTo } from 'rxjs/operators';
import { DefaultMock, Mock } from 'app/services/mock';
import { testMode } from 'app/services/misc';


declare var window: WindowInterface;

@Injectable()
export abstract class AbstractService {
    constructor(public xhr: XHR) {
    }

    getMock<R, T>(requestParams: RequestParams<R>, sourceParams: T): Observable<R> | null {
        // DO NOT REPLACE THIS WITH if(!TEST) { return null; }
        // positive condition is needed for tree-shaking of `Mock`
        if (TEST && testMode(this.constructor.name) && requestParams.method !== void 0) {
            const method = requestParams.method.join(' ');
            const hasMock: boolean = !!window.i360Test.mock[method];

            const mock = hasMock ? window.i360Test.mock[method]
                : this.getDefaultMock(requestParams, sourceParams);

            const result = {
                ...{result: 'success', messages: []},
                ...mock,
                ...{
                    data: {...Mock.defaultData, ...mock.data},
                },
            };

            console.log(`XHR request:`, requestParams);
            console.log(`XHR ${hasMock ? '' : 'default '}mock: %c${method}`, `color:green`, result);

            // simulating asynchronous run
            return timer(0).pipe(
                mapTo(result),
            );
        }

        return null;
    }


    getDefaultMock<R, T>(requestParams: RequestParams<R>, sourceParams: T): {data: any} | null {
        if (!TEST) {
            return null;
        }

        for (let item of this.getDefaultMockList()) {
            let source;
            try {
                source = item.api(sourceParams);
            } catch { continue; }  // inappropriate sourceParams for this api, skip
            if (source.method !== void 0 && requestParams.method !== void 0
                && source.method.join(' ') === requestParams.method.join(' ')) {
                return item.response instanceof Function
                    ? item.response(sourceParams)
                    : item.response;
            }
        }

        return {data: {}};
    }

    abstract getDefaultMockList(): Array<DefaultMock<any, any, any>>;

    /**
     * Wraps API method to create a service method.
     */
    serviceRequest<T, R>(
        api: ApiMethod<T, R>,
        notifyOnWarningDefault: boolean = true,
        notifyOnErrorDefault: boolean = true,
    ) {
        // NOTE: we cannot use "...args" instead of "parameters", because of this:
        // https://github.com/Microsoft/TypeScript/issues/5453
        // So, we won't be able to pass types unless we do smth like this:
        // https://github.com/Microsoft/TypeScript/blob/master/lib/lib.es2015.promise.d.ts#L41-L113
        return (
            params?: T,
            notifyOnWarning: boolean = notifyOnWarningDefault,
            notifyOnError: boolean = notifyOnErrorDefault,
        ) => {
            const requestParams = api(params);
            return this.xhr.post<R>(
                requestParams,
                () => this.getMock(requestParams, params),
                notifyOnWarning,
                notifyOnError,
            ).toPromise();
        };
    }

    rxServiceRequest<T, R>(
        api: ApiMethod<T, R>,
        notifyOnWarningDefault: boolean = true,
        notifyOnErrorDefault: boolean = true,
    ) {
        return (
            params?: T,
            notifyOnWarning: boolean = notifyOnWarningDefault,
            notifyOnError: boolean = notifyOnErrorDefault,
        ) => {
            const requestParams = api(params);
            return this.xhr.post<R>(
                requestParams,
                () => this.getMock(requestParams, params),
                notifyOnWarning,
                notifyOnError,
            );
        };
    }

    /**
     * converts items in AgentResponse from list of P to list of itemClass
     */
    wrap<E, P, R>(serviceMethod: (params: E) => Observable<AgentResponse<ListResponseData<P>>>,
                  itemClass: {new(data: P): R}):
        (params: E) => Observable<AgentResponse<ListResponseData<R>>> {
        return (params: E) => {
            return serviceMethod(params).pipe(
                map(({data, result, messages}) => ({
                    data: {
                        items: data.items.map(item => new itemClass(item)),
                        version: data.version,
                        strategy: data.strategy,
                        license: data.license,
                        eula: data.eula,
                        max_count: 'max_count' in data ? data.max_count : data.items.length,
                    },
                    result,
                    messages,
                }),
            ));
        };
    }
}
