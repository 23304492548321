import { ModuleWithProviders, NgModule } from '@angular/core';
import { TranslateService } from 'app/utils/translate/translate.service';
import { TranslateCutPipe } from 'app/utils/translate/translate-cut';
import { TranslateModule, TranslateService as LibTranslateService } from '@ngx-translate/core';
import { DefaultIfNotTranslatedComponent } from './default-if-not-translated.component';
import { CommonModule } from '@angular/common';


@NgModule({
    imports: [
        TranslateModule,
        CommonModule,
    ],
    declarations: [
        TranslateCutPipe,
        DefaultIfNotTranslatedComponent,
    ],
    exports: [
        TranslateCutPipe,
        TranslateModule,
        DefaultIfNotTranslatedComponent,
    ],
})
export class I360TranslateModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: I360TranslateModule,
            providers: [
                TranslateService,
                LibTranslateService, // why???
            ],
        };
    }
}
