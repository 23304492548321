import * as tslib_1 from "tslib";
import { AbstractService } from 'app/services/abstract-service';
import { InstallationAPI } from '@imunify360-api/installation';
import { responses } from '@imunify360-api/stubs/installation';
import { Mock } from 'app/services/mock';
import * as i0 from "@angular/core";
import * as i1 from "../../utils/xhr";
var InstallationBackendService = /** @class */ (function (_super) {
    tslib_1.__extends(InstallationBackendService, _super);
    function InstallationBackendService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.status = _this.rxServiceRequest(InstallationAPI.status);
        _this.start = _this.serviceRequest(InstallationAPI.start);
        return _this;
    }
    InstallationBackendService.prototype.getDefaultMockList = function () {
        return !TEST ? [] : [
            Mock.add(InstallationAPI.status, responses.pieceOfLog),
            Mock.add(InstallationAPI.start, responses.serviceFailedToInstall),
        ];
    };
    InstallationBackendService.ngInjectableDef = i0.defineInjectable({ factory: function InstallationBackendService_Factory() { return new InstallationBackendService(i0.inject(i1.XHR)); }, token: InstallationBackendService, providedIn: "root" });
    return InstallationBackendService;
}(AbstractService));
export { InstallationBackendService };
