import { Injectable } from '@angular/core';
import { TranslateParams, TranslateService } from 'app/utils/translate/translate.service';


@Injectable({
    providedIn: 'root',
})
export class NotificationsService {
    notifications: Notification[] = [];

    constructor(private translate: TranslateService) {}

    success(content: string, title: string|null = null, params?: TranslateParams) {
        return this.add(title || `notifications.success`, content, 'success', params);
    }

    error(content: string, title: string|null = null, params?: TranslateParams) {
        return this.add(title || `notifications.error`, content, 'error', params);
    }

    warning(content: string, title: string|null = null, params?: TranslateParams) {
        return this.error(content, title || `notifications.warning`, params);
    }

    remove(item: Notification): void {
        this.notifications = this.notifications.filter(i => !this.equal(i, item));
    }

    private async add(title: string,
                      content: string,
                      type: string,
                      params: TranslateParams|undefined): Promise<void> {
        const item = new Notification({
            title: await this.translate.t(title, params),
            content: await this.translate.t(content, params),
            type,
        });

        for (let i of this.notifications) {
            if (this.equal(i, item)) {
                i.counter++;
                i.ticks = 0;
                return;
            }
        }

        item.counter = 1;
        this.notifications.push(item);
    }

    private equal(a: Notification, b: Notification) {
        return a.type === b.type
            && a.title === b.title
            && a.content === b.content;
    }
}

export class Notification {
    type: string;
    title: string;
    content: string;
    counter: number = 0;
    ticks: number = 0;

    constructor(item: {type: string, title: string, content: string}) {
        this.type = item.type;
        this.title = item.title;
        this.content = item.content;
    }
}
