import * as tslib_1 from "tslib";
export var noSentryErrorMessage = 'noSentryErrorMessage';
var NoSentryError = /** @class */ (function (_super) {
    tslib_1.__extends(NoSentryError, _super);
    function NoSentryError() {
        var _this = _super.call(this, noSentryErrorMessage) || this;
        _this.noSentry = true;
        // Set the prototype explicitly.
        Object.setPrototypeOf(_this, NoSentryError.prototype);
        return _this;
    }
    return NoSentryError;
}(Error));
export { NoSentryError };
