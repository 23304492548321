/*
 * Angular bootstraping
 */
import 'bootstrap-sass'; // FIXME: bootstrap
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { decorateModuleRef } from 'app/core/environment';
import { AppModule } from 'app/core/app.module';
import { bootstrapApp, loadTestInterceptor } from 'app/core/misc';

if (TEST) {
    loadTestInterceptor();
}

declare let __webpack_public_path__: string;

/*
 * Bootstrap our Angular app with a top level NgModule
 *
 * We access i360role when DomContentLoaded event is emitted, so all scripts in page, added
 * on server side, has been executed, and i360role is 100% defined.
 *
 * We need 'require' to use webpack cache of assetsPath variable. If we use getAssetsPath
 * there may be a risk to have different assetsPath in different places
 * (there is one more place where it is used)
 */
export function main(): Promise<any> {
    __webpack_public_path__ = require('./assets').assetsPath;
    return platformBrowserDynamic()
        .bootstrapModule(AppModule)
        .then(decorateModuleRef)
        .catch(err => console.error(err));
}

bootstrapApp(main);
